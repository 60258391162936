import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

interface Props {
  title: string;
  description?: string;
  path?: string;
  image?: string;
}

const SEO = ({ props = {} as Props }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          baseUrl
          image
        }
      }
    }
  `);

  const defaults = data.site.siteMetadata;

  if (defaults.baseUrl === "" && typeof window !== "undefined") {
    defaults.baseUrl = window.location.origin;
  }

  if (defaults.baseUrl === "") {
    console.error("Please set a baseUrl in your site metadata!");
    return null;
  }

  const title = props.title || defaults.title;
  const description = props.description || defaults.description;
  const url = new URL(props.path || "", defaults.baseUrl);
  const image = props.image || defaults.image;

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={url.href} />
      <meta name="description" content={description} />
      {image && <meta name="image" content={image} />}

      <meta property="og:url" content={url.href} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={"/images/logo-icon-web-x512.png"} />
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Corporation",
          name: "Hatio Innovations Private Limited",
          alternateName: "Hatio Innovations Private Limited",
          url: "https://hatio.in/",
          logo: "https://hatio.in/images/logo-icon-web-x512.png",
          contactPoint: {
            "@type": "ContactPoint",
            telephone: "+91 080 4568 1234",
            contactType: "sales",
            areaServed: "IN",
            availableLanguage: ["en", "Hindi"],
          },
          sameAs: [
            "https://linkedin.com/company/hatio",
            "https://www.facebook.com/hatio.in/",
            "https://twitter.com/hatio_in",
          ],
        })}
      </script>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "FinancialService",
          name: "Hatio Innovations Private Limited",
          image: "https://hatio.in/images/logo-icon-web-x512.png",
          "@id": "",
          url: "https://hatio.in/",
          telephone: "+91 080 4568 1234",
          address: {
            "@type": "PostalAddress",
            streetAddress:
              "Trans Asia Cyber Park, Infopark Phase 2, Infopark Sez, Phase II",
            addressLocality: "Kochi",
            postalCode: "682303",
            addressCountry: "IN",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 10.0041324,
            longitude: 76.3756227,
          },
          sameAs: [
            "https://www.facebook.com/hatio.in/",
            "https://linkedin.com/company/hatio",
            "https://hatio.in/",
            "https://twitter.com/hatio_in",
          ],
        })}
      </script>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "FAQPage",
          mainEntity: [
            {
              "@type": "Question",
              name: "What is Hatio Payouts?",
              acceptedAnswer: {
                "@type": "Answer",
                text: "Hatio Payouts is an API first digital platform that helps merchants and financial institutions to simplify and automate their payout transactions.",
              },
            },
            {
              "@type": "Question",
              name: "What can I use Hatio Payouts for?",
              acceptedAnswer: {
                "@type": "Answer",
                text: "Hatio Payouts can be used for a wide variety of payout needs by merchants or small startups. \nSome of the use cases are as follows \n- Transfer funds to suppliers and vendor instantaneously\n - Salary disbursement to employees \n - Cash back for loyalty programs to customers \n- Disbursement of loans to lending institutions",
              },
            },
            {
              "@type": "Question",
              name: "How is Hatio Payouts different from competitors?",
              acceptedAnswer: {
                "@type": "Answer",
                text: "Hatio Payouts is an API first platform, and is developed to address the issues with existing payment services provided by Banks. Some of our USPs are as follows. \n- We are a fully owned subsidiary of BillDesk, India’s largest payment processor company, we adhere to strict AML and financial regulations compliance.\n - New beneficiary addition and activation is instant.",
              },
            },
            {
              "@type": "Question",
              name: "Can I negotiate on pricing if I have large requirements?",
              acceptedAnswer: {
                "@type": "Answer",
                text: "Yes, we provide flexible pricing methods for our customers based on their transaction volumes. Please contactsales@hatio.techfor more information.",
              },
            },
            {
              "@type": "Question",
              name: "How do I integrate Hatio Payouts?",
              acceptedAnswer: {
                "@type": "Answer",
                text: "Please refer to our APIdocto follow the step by step process to integrate Hatio Payouts with your application",
              },
            },
            {
              "@type": "Question",
              name: "Do you support all bank accounts?",
              acceptedAnswer: {
                "@type": "Answer",
                text: "Yes, we support transactions to all virtual and non-virtual bank accounts.",
              },
            },
            {
              "@type": "Question",
              name: "Can I transfer funds on Sundays and Bank Holidays?",
              acceptedAnswer: {
                "@type": "Answer",
                text: "Yes, NEFT, IMPS and UPI modes of bank transfers are available 356 days and 24hrs round the clock. However, RTGS is available at bank working days between 09:00 to 18:00 Hrs except Sundays and bank holidays.",
              },
            },
          ],
        })}
      </script>
    </Helmet>
  );
};

export default SEO;
